import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import Modal from './Modal'; 



const CategoryButton = styled.button`
  background-color: ${(props) => (props.$active ? '#f00' : '#ccc')}; /* Använd $ för transient props */
  margin-right: 15px;
  margin-bottom: 20px;
  margin-left: 10px;
  margin-top: 20px;
  padding: 10px 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
  cursor: pointer;
  font-weight: ${(props) => (props.$active ? 'bold' : 'normal')}; /* Markera om aktiv */

  @media (max-width: 768px) {
    padding: 5px 10px;
    font-size: 12px;
    margin-right: 8px;
    margin-top: 20px;
    justify-items: center;
  }
`;

const ProductGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Tre produkter per rad på större skärmar */
  gap: 20px; /* Minska gapet mellan produkterna */
  padding: 0;
  margin-left: 10px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr; /* En produkt per rad på mobiler */
    gap: 10px;
    margin-top: 20px;
    justify-items: center;
  }
`;

const ProductItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 5px;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 450px; /* Sätt en fast höjd för alla produkter */
  transition: transform 0.2s ease;
  margin-left: 0;
  max-width: 300px;

  &:hover {
    transform: translateY(-5px);
    cursor: pointer;
  }

  img {
    height: auto;
    max-height: 280px; /* Minska höjden på bilderna */
    object-fit: cover;
  }

  h3 {
    font-size: 16px;
    margin: 5px 0;
  }

  @media (max-width: 768px) {
    padding: 5px;
    height: auto; /* Låt produkterna vara flexibla på mobiler */
    width: 80%;

    h3 {
      font-size: 16px;
    }

    img {
      min-width: 80%;
      min-height: 150px;
    }
  }
`;

const ProductImageSlider = ({ images }) => {
  const [currentImage, setCurrentImage] = useState(0);

  const handleNext = (e) => {
    e.stopPropagation(); // Förhindra modal från att öppnas
    setCurrentImage((prev) => (prev + 1) % images.length);
  };

  const handlePrev = (e) => {
    e.stopPropagation(); // Förhindra modal från att öppnas
    setCurrentImage((prev) => (prev - 1 + images.length) % images.length);
  };

  return (
    <div style={{ position: 'relative', textAlign: 'center' }}>
      <img src={images[currentImage]} alt="Product" style={{ width: '100%' }} />
      {images.length > 1 && (
        <>
          <button
            onClick={handlePrev}
            style={{
              position: 'absolute',
              top: '50%',
              left: '10px',
              transform: 'translateY(-50%)',
              backgroundColor: '#f3e5d5',
              color: 'black',
              border: 'none',
              borderRadius: '50%',
              padding: '10px',
              fontSize: '16px',
              cursor: 'pointer',
              outline: 'none',
            }}
          >
            ←
          </button>
          <button
            onClick={handleNext}
            style={{
              position: 'absolute',
              top: '50%',
              right: '10px',
              transform: 'translateY(-50%)',
              backgroundColor: '#f3e5d5',
              color: 'black',
              border: 'none',
              borderRadius: '50%',
              padding: '10px',
              fontSize: '16px',
              cursor: 'pointer',
              outline: 'none',
            }}
          >
            →
          </button>
        </>
      )}
    </div>
  );
};

const Price = styled.div`
  font-weight: bold;
  font-size: 14px; /* Minska textstorleken för priset */
  margin-top: 5px;
  margin-bottom: 5px; /* Minska avståndet till knappen */
  min-height: unset;
  line-height: 1.2;
`;




const ColorDotContainer = styled.div`
  margin-top: 0px;
  display: flex;
  justify-content: center;
  gap: 5px;
`;

const ColorDot = styled.div`
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: ${(props) => props.color};
  border: ${(props) => (props.selected ? '2px solid black' : '1px solid #ccc')};
  cursor: pointer;
`;

function ProductList({ addToCart }) {
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('Alla produkter');
  const [selectedColors, setSelectedColors] = useState({});
  const [selectedProduct, setSelectedProduct] = useState(null); // För modal
  const [modalSize, setModalSize] = useState(''); // För att spåra storleksval i modalen
  const [selectedColor, setSelectedColor] = useState(''); // För att spåra färgval i modalen
  const [promotionMessage, setPromotionMessage] = useState(''); // Nytt state för textmeddelande
  const [showPromotionMessage, setShowPromotionMessage] = useState(false); // Visar endast meddelandet vid "Erbjudanden"

  useEffect(() => {
    const sheetId = '1l-cBEargERH-V8_6Bk95MrOXK-ZZ58N4BDYaoF7gwfY';
    const apiKey = 'AIzaSyCEOI30hQx0B_Hsbm7-QO58pWhBtXYU5_M';
    const baseURL = `https://sheets.googleapis.com/v4/spreadsheets/${sheetId}/values/Blad1!A2:K?key=${apiKey}`; // Uppdaterad för att inkludera kolumn J och K

    axios
      .get(baseURL)
      .then((response) => {
        const rows = response.data.values;
        const formattedProducts = rows.map((row) => ({
          id: row[0],
          name: row[1],
          description: row[2],
          price: row[3],
          images: row[4]
            ? row[4].split(',').map((url) => url.replace(/\n/g, '').replace(/\r/g, '').trim())
            : [],
          category: row[5],
          color: row[6] || '',
          size: row[7] || '',
          sizeGuideImage: row[8] || '',
          isOffer: row[9] === 'Ja', // Nytt fält för erbjudande
          message: row[10] || '', // Nytt fält för textmeddelande
        }));

        const validProducts = formattedProducts.filter((product) => product.category);
        setProducts(validProducts);
        setFilteredProducts(validProducts);
        const uniqueCategories = ['Alla produkter', ...new Set(validProducts.map((product) => product.category))];
        setCategories(uniqueCategories);
        setPromotionMessage(validProducts[0]?.message || ''); // Sätt promotion-meddelandet
      })
      .catch((error) => {
        console.error('Error fetching data from Google Sheets:', error);
      });
  }, []);

  const filterProductsByCategory = (category) => {
    setSelectedCategory(category);
    if (category === 'Alla produkter') {
      setFilteredProducts(products);
      setShowPromotionMessage(false); // Visa inte texten för "Alla produkter"
    } else {
      setFilteredProducts(products.filter((product) => product.category === category));
      setShowPromotionMessage(false); // Visa inte texten för andra kategorier
    }
  };

  const filterProductsByOffer = () => {
    setSelectedCategory('Erbjudanden');
    setFilteredProducts(products.filter((product) => product.isOffer));
    setShowPromotionMessage(true); // Visa textmeddelandet när "Erbjudanden" är aktiv
  };

  const handleColorSelect = (productId, color) => {
    setSelectedColors({
      ...selectedColors,
      [productId]: color, // Sätt färgen för produkten till den senaste valda
    });
    setSelectedColor(color); // Uppdatera den aktuella färgen
  };
  
  const handleAddToCart = (product) => {
    const productWithSelectedOptions = {
      ...product,
      color: selectedColor || product.color, // Använd den senaste valda färgen
      size: modalSize, // Inkludera den valda storleken
    };
  
    addToCart(productWithSelectedOptions);
    setSelectedProduct(null); // Stäng modalen efter tillägg
  };
  
  
  // Resterande kod i komponenten...
  

  const handleProductClick = (product) => {
    setSelectedProduct(product); // Öppna modal och skicka vald produkt
    setModalSize(''); // Återställ storleksval i modalen
    setSelectedColor(''); // Återställ färgval i modalen
  };

  const renderColorDots = (colors, productId) => {
    if (!colors) return null;
    return colors.split(',').map((color) => (
      <ColorDot
        key={color.trim()}
        color={color.trim()}
        selected={selectedColors[productId] === color.trim()}
        onClick={() => handleColorSelect(productId, color.trim())} // Hantera färgval
      />
    ));
  };
  

  return (
    <div>
      <div>
        <img src="/images/komphead.png" alt="Header" style={{ width: '100%', height: 'auto', maxHeight: '550px', objectFit: 'cover', paddingBottom: '40px' }} />
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        {/* Kategoriknappar */}
        <div>
          {categories.map((category) => (
            <CategoryButton key={category} $active={category === selectedCategory} onClick={() => filterProductsByCategory(category)}>
              {category}
            </CategoryButton>
          ))}
        </div>

        {/* Erbjudanden-knappen längst till höger */}
        <div>
          <CategoryButton
            key="Erbjudanden"
            $active={selectedCategory === 'Erbjudanden'}
            onClick={filterProductsByOffer}
            style={{
              backgroundColor: '#000080',
              color: 'white',
              fontWeight: 'bold',
            }}
          >
            Erbjudanden
          </CategoryButton>
        </div>
      </div>

      {/* Visa textmeddelandet endast när "Erbjudanden" är vald */}
      {showPromotionMessage && promotionMessage && <p style={{ marginTop: '20px', marginLeft: '20px', fontSize: '18px', color: 'green' }}>{promotionMessage}</p>}

      <ProductGrid>
        {filteredProducts.map((product) => (
          <ProductItem key={product.id} onClick={() => handleProductClick(product)}>
            <h3>{product.name}</h3>
            <ProductImageSlider images={product.images} />
            <ColorDotContainer>{renderColorDots(product.color, product.id)}</ColorDotContainer>
            <Price>Pris: {product.price} kr</Price>
          </ProductItem>
        ))}
      </ProductGrid>

      {selectedProduct && (
        <Modal
          product={selectedProduct}
          selectedSize={modalSize}
          setSelectedSize={setModalSize}
          selectedColor={selectedColor}
          setSelectedColor={setSelectedColor} // Lägg till färgval i modalen
          handleAddToCart={handleAddToCart}
          onClose={() => setSelectedProduct(null)} // Stänger modal
        />
      )}
    </div>
  );
}

export default ProductList;
