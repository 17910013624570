import React, { useState } from 'react';
import AppRouter from './Navigation/AppRouter';

function App() {
  const [cart, setCart] = useState([]);

  const addToCart = (product) => {
    setCart((prevCart) => [...prevCart, product]);
  };

  return (
    <div className="App">
      <AppRouter cart={cart} setCart={setCart} addToCart={addToCart} /> {/* Skicka setCart här */}
    </div>
  );
}

export default App;
