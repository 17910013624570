// src/screens/homeStyles.js
const homeStyles = {
  container: {
    maxWidth: '1000px',
    margin: '0 auto',
    padding: '20px',
    lineHeight: '1.6',
    fontSize: '16px',
    color: '#333',
    textAlign: 'center', // För att centrera text och innehåll

  },
  image: {
    width: '100%',
    margin: '10px 0',
    borderRadius: '1px',
    padding: '0px',
    
  },
};

// För mindre skärmar kan du skapa ett objekt för media queries
const mobileStyles = {
  container: {
    padding: '10px',
    fontSize: '14px',
    
  },
  image: {
    margin: '10px 0',
    width: '100%',
  },
};

export { homeStyles, mobileStyles };
