import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { FaTrashAlt } from 'react-icons/fa'; // Importera papperskorgsikonen från react-icons

const FormContainer = styled.div`
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
`;

const FormRow = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

const Label = styled.label`
  margin-bottom: 8px;
  font-weight: bold;
`;

const Input = styled.input`
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
`;



const SubmitButton = styled.button`
  width: 100%;
  padding: 15px;
  background-color: #007bff;
  color: white;
  font-size: 18px;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }
`;

const PaymentNotice = styled.p`
  margin-bottom: 20px;
  font-weight: bold;
  color: red;
`;

const CartItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #ccc;
`;

const CartItemInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

const RemoveButton = styled.button`
  background: none;
  border: none;
  color: red;
  cursor: pointer;
  font-size: 20px; /* Öka storleken på ikonen */
  padding: 10px; /* Öka paddingen för att göra knappen större */
`;

const TotalAmount = styled.h3`
  margin-top: 20px;
`;

const ExtraNotice = styled.p`
  margin-top: 10px;
  color: #555;
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background: white;
  padding: 20px;
  border-radius: 10px;
  max-width: 400px;
  max-height: 400px;
  text-align: center;
  position: relative;
`;

const VillkorModalContent = styled(ModalContent)`
  max-height: none;
  padding: 0;
  img {
    border-radius: 10px;
    display: block;
    margin-top: 0;
    width: 100%;
    height: auto;
  }
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  font-size: 20px;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  color: black;
  z-index: 10;
`;

const TermsLink = styled.span`
  color: blue;
  text-decoration: underline;
  cursor: pointer;
`;

const TermsContainer = styled.div`
  margin-bottom: 20px;
`;

const TermsCheckbox = styled.input`
  margin-right: 10px;
`;

const DeliveryOption = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  margin-bottom: 20px;
`;

function Pay({ cart, setCart }) {
  const navigate = useNavigate();
  const [form, setForm] = useState({
    firstName: '',
    lastName: '',
    address: '',
    postCode: '',
    city: '',
    phoneNumber: '',
    emailAddress: '',
  });

  const [orderSent, setOrderSent] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [isTermsModalOpen, setIsTermsModalOpen] = useState(false);
  const [deliveryMethod, setDeliveryMethod] = useState(''); // Nytt state för leveransmetod

  const totalAmount = cart.reduce((total, product) => total + parseInt(product.price), 0);

  // Hanterar borttagning av en produkt
  const handleRemoveFromCart = (index) => {
    const updatedCart = cart.filter((_, i) => i !== index); // Tar bort produkten vid index
    setCart(updatedCart); // Uppdaterar varukorgen
  };

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const handleTermsModal = () => {
    setIsTermsModalOpen(!isTermsModalOpen);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  
    if (!termsAccepted) {
      alert('Var vänlig läs villkoren och godkänn dem.');
      return;
    }
  
    if (!deliveryMethod) {
      alert('Vänligen välj ett leveransalternativ.');
      return;
    }
  
    // Skicka e-post via EmailJS
    emailjs.send(
      'service_54yi5ua',
      'template_f331omg',
      {
        to_name: 'Josefine',
        from_name: `${form.firstName} ${form.lastName}`,
        address: `${form.address}, ${form.postCode} ${form.city}`,
        phone: form.phoneNumber || 'Inte angivet',
        totalAmount: totalAmount,
        paymentMethod: 'Faktura',
        deliveryMethod: deliveryMethod, // Lägg till leveransmetod
        items: cart.map((product) => `${product.name} - Färg: ${product.color || 'Ingen'} - Storlek: ${product.size || 'Ingen'} - ${product.price} kr`).join('\n'),
        email: form.emailAddress,
      },
      'qowOpVUvPRBbT9ZeC'
    ).then(() => {
      setCart([]); // Töm varukorgen här
      setOrderSent(true);
      setTimeout(() => {
        navigate('/');
      }, 3000);
    }, (error) => {
      console.error('E-postfel:', error);
      alert('Ett fel inträffade, försök igen senare.');
    });
  };
  

  return (
    <FormContainer>
      {orderSent ? (
        <div>
          <h2>Din beställning är skickad!</h2>
          <p>Du kommer att skickas till startsidan om ett ögonblick.</p>
        </div>
      ) : (
        <>
          <h2>Din Varukorg</h2>
          {cart.map((product, index) => (
            <CartItem key={index}>
              <CartItemInfo>
                <strong>{product.name}</strong>
                <span>Färg: {product.color || 'Ingen'}</span>
                <span>Storlek: {product.size || 'Ingen'}</span> {/* Här visas endast den valda storleken */}
                <span>{product.price} kr</span>
              </CartItemInfo>
              <RemoveButton onClick={() => handleRemoveFromCart(index)}>
                <FaTrashAlt />
              </RemoveButton>
            </CartItem>
          ))}

          <TotalAmount>Total: {totalAmount} kr</TotalAmount>

          <ExtraNotice>
            Eventuell rabatt eller fraktkostnad läggs på/dras av i fakturan. Standardfrakt är 49 kronor och fri frakt vid summa över 599. Om du vill hämta produkterna i Östadkulle (Vårgårda) är frakt gratis, adress: Furulundsvägen 6A
44192 Alingsås.
          </ExtraNotice>

          <form onSubmit={handleSubmit}>
            <FormRow>
              <Label>Förnamn:</Label>
              <Input
                type="text"
                name="firstName"
                value={form.firstName}
                onChange={handleChange}
                required
              />
            </FormRow>

            <FormRow>
              <Label>Efternamn:</Label>
              <Input
                type="text"
                name="lastName"
                value={form.lastName}
                onChange={handleChange}
                required
              />
            </FormRow>

            <FormRow>
              <Label>Adress:</Label>
              <Input
                type="text"
                name="address"
                value={form.address}
                onChange={handleChange}
                required
              />
            </FormRow>

            <FormRow>
              <Label>Postnummer:</Label>
              <Input
                type="text"
                name="postCode"
                value={form.postCode}
                onChange={handleChange}
                required
              />
            </FormRow>

            <FormRow>
              <Label>Ort:</Label>
              <Input
                type="text"
                name="city"
                value={form.city}
                onChange={handleChange}
                required
              />
            </FormRow>

            <FormRow>
              <Label>Telefonnummer (frivilligt):</Label>
              <Input
                type="text"
                name="phoneNumber"
                value={form.phoneNumber}
                onChange={handleChange}
              />
            </FormRow>

            <FormRow>
              <Label>E-postadress:</Label>
              <Input
                type="email"
                name="emailAddress"
                value={form.emailAddress}
                onChange={handleChange}
                required
              />
            </FormRow>

            {/* Villkor för beställning */}
            <TermsContainer>
              <TermsCheckbox
                type="checkbox"
                checked={termsAccepted}
                onChange={() => setTermsAccepted(!termsAccepted)}
              />
              <span>Jag har läst och godkänt </span>
              <TermsLink onClick={handleTermsModal}>Villkor för beställning</TermsLink>
            </TermsContainer>

            {/* Leveransalternativ */}
            <DeliveryOption>
              <label>
                <input
                  type="radio"
                  name="deliveryMethod"
                  value="Hämtas i Vårgåda"
                  onChange={(e) => setDeliveryMethod(e.target.value)}
                  required
                />
                <span>Jag vill hämta produkterna i Östadkulle</span>
              </label>
              <label>
                <input
                  type="radio"
                  name="deliveryMethod"
                  value="Skickas"
                  onChange={(e) => setDeliveryMethod(e.target.value)}
                  required
                />
                <span>Jag vill att produkterna skickas</span>
              </label>
            </DeliveryOption>

            {/* Betalningsinformation */}
            <PaymentNotice>Betalning sker endast vid faktura.</PaymentNotice>

            <SubmitButton type="submit">Beställ</SubmitButton>
          </form>

          {/* Modal för villkor */}
          {isTermsModalOpen && (
            <ModalOverlay>
              <VillkorModalContent>
                <CloseButton onClick={handleTermsModal}>&times;</CloseButton>
                <h4>Villkor för beställning</h4>
                <img src="/images/VILLKOR.png" alt="Villkor för beställning" />
              </VillkorModalContent>
            </ModalOverlay>
          )}
        </>
      )}
    </FormContainer>
  );
}

export default Pay;
