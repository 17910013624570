import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px;
  line-height: 1.6;
  font-size: 16px;
  color: #333;

  @media (max-width: 768px) {
    padding: 10px;
  }
`;

const Image = styled.img`
  float: right;
  width: 200px;
  height: auto;
  margin: 0 0 30px 20px;
  border-radius: 8px;

  @media (max-width: 768px) {
    float: right;         /* Fortsätt flyta bilden till höger på mobil */
    width: 130px;         /* Gör bilden mindre på mobiler */
    margin: 0 0 5px 7px; /* Justera marginalen för mobil */
  }
`;

function About() {
  return (
    <Container>
      <Image src="/images/jossan.png" alt="Josefine Image" />
      <h1>Om Mig</h1>
      <p>
        Josefines Wellness grundades av Josefine Rosén den 1 december 2011 och erbjuder ett brett utbud av behandlingar inom friskvård och skönhet. Genom åren har jag, Josefine, utvecklat mitt företag och utökat tjänsteutbudet genom kontinuerlig utbildning och fortbildning. För närvarande studerar jag till sjuksköterska, vilket ger mig ytterligare insikt och kompetens inom hälso- och sjukvården.
        <br /><br />
        År 2008 när jag var 17 år började jag uppleva smärta och svullnad i kroppen. Efter många år av läkarbesök fick jag år 2022 diagnosen lipödem, en sjukdom som orsakar kraftig svullnad och smärta i delar av kroppen. Lipödem är en sjukdom som jag brinner för att uppmärksamma och hjälpa andra att hantera.
        <br /><br />
        Efter att ha läst och provat nästan allt som fanns på marknaden upptäckte jag att kompressionstights med mikromasserande verkan gav bäst effekt. Men jag funderade på om det inte kunde finnas fler klädesplagg som gav samma stöd och hjälp. Det var så jag kom i kontakt med Solidea och deras kompressionskläder. Dessa plagg har inte bara hjälpt mig, utan de har också blivit en viktig del av det jag erbjuder på Josefines Wellness.
        <br /><br />
        Kompressionskläder är inte bara till för dem som har lipödem. De är utformade för att förbättra blodcirkulationen, minska svullnad och lindra trötthet i musklerna. De kan vara fördelaktiga för alla som står eller sitter mycket under dagen, idrottare som vill förbättra sin prestation och återhämtning, eller för dem som vill förebygga åderbråck och blodproppar. Genom att ge ett jämnt tryck över kroppen hjälper kompressionskläderna till att öka blodflödet, vilket kan minska risken för svullnad och förbättra allmänt välbefinnande.
        <br /><br />
        På Josefines Wellness är vårt mål att erbjuda produkter och behandlingar som förbättrar din livskvalitet och hjälper dig att må bättre i din vardag. Oavsett om du har en specifik diagnos eller bara vill ta hand om din kropp på bästa sätt, kan kompressionskläder vara ett värdefullt verktyg för att stödja din hälsa och ditt välmående.
      </p>
    </Container>
  );
}

export default About;
