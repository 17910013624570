import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const NextArrow = (props) => {
  const { onClick } = props;
  return (
    <div
      style={{
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        right: '-30px', // Justera för bättre synlighet
        fontSize: '34px',
        cursor: 'pointer',
        zIndex: 1,
      }}
      onClick={onClick}
    >
      →
    </div>
  );
};

const PrevArrow = (props) => {
  const { onClick } = props;
  return (
    <div
      style={{
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        left: '-30px', // Justera för bättre synlighet
        fontSize: '34px',
        cursor: 'pointer',
        zIndex: 1,
      }}
      onClick={onClick}
    >
      ←
    </div>
  );
};

function LipodemScreen() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    appendDots: (dots) => (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: '10px',
        }}
      >
        {dots}
      </div>
    ),
  };

  return (
    <div
      style={{
        maxWidth: '900px',
        margin: '0 auto',
        padding: '20px',
        fontSize: '16px',
        lineHeight: '1.6',
        color: '#333',
      }}
    >
      <img
        src="/images/lip.png"
        alt="Lipödem Rubrik"
        style={{ width: '100%', height: 'auto', marginBottom: '20px' }}
      />

      <p>
        Lipödem är en kronisk och ärftlig sjukdom som nästan uteslutande drabbar kvinnor. Sjukdomen bryter ofta ut i samband med hormonella förändringar, som under puberteten, graviditet eller i samband med menopaus.
        <br /><br />
        Lipödem kännetecknas av att fettcellerna i kroppen ökar i storlek, vilket leder till en ökning av omfånget, särskilt över stuss, höfter och lår. I senare stadier kan även överkroppen påverkas. Huden kan kännas mjukare och ibland kall, och den underliggande fettvävnaden kan vara ojämn med både små och stora fettknölar. Utöver fettinlagring kan lipödem leda till andra vävnadsförändringar, såsom minskad elasticitet i huden och stödjevävnaden.
        <br /><br />
        Det är en ofta misstolkad diagnos, där lipödem felaktigt diagnostiseras som fetma trots att det finns viktiga skillnader. Till exempel orsakar lipödem smärta och kan inte bantas bort, eftersom lipödem-fett endast sitter i vissa delar av kroppen, inte överallt. Forskning indikerar också att en inflammation pågår i den drabbade vävnaden, vilket skiljer lipödem från fetma. Lipödem kan också förväxlas med lymfödem, men det finns viktiga skillnader mellan de två tillstånden. Det finns andra sjukdomstillstånd som liknar lipödem, såsom onormal fettinlagring i underhuden och minskad elasticitet i bindväven. Symtomen kan överlappa och det är inte ovanligt att flera diagnoser förekommer samtidigt. 
        <br /><br />
        Vanliga symptom för lipödem är smärta och svullnad i de drabbade områdena, oproportionerlig kroppsform, ojämn/ knölig hud, överrörliga leder samt lättare att få blåmärken. 
        <br /><br />
        Forskningen om lipödem är begränsad, vilket delvis beror på att sjukdomen primärt drabbar kvinnor och historiskt sett har kvinnors hälsoproblem fått mindre uppmärksamhet inom medicinsk forskning. Lipödem har varit känt inom medicinska kretsar sedan 1940-talet, men det har tagit lång tid för sjukdomen att få erkännande och förståelse. Det är därför viktigt att få rätt diagnos och att vårdgivare har kunskap om lipödem för att ge rätt råd och behandling. Felaktig behandling kan förvärra tillståndet och i vissa fall kan ett obehandlat lipödem leda till utveckling av lymfödem.
        <br /><br />
        Behandling för lipödem inkluderar kompressionskläder, lymfmassage, vattenträning, ir - madrass, vibrationsplatta och i vissa fall kirurgiska ingrepp. Egenvården är otroligt viktig som underhållsbehandling då denna sjukdom inte går att bota. Ofta får man stå för dessa produkter själv. Vissa regioner betalar viss kompressionsbehandling samt lymfmassage, men långtifrån alla.
        <br /><br />
        Lipödem är något som ligger mig mycket varmt om hjärtat, och jag är engagerad i att öka medvetenheten om denna sjukdom. Under åren har jag deltagit i flera cykellopp, även med tyngder, för att samla in pengar till forskning om lipödem och stödja arbetet med att hitta bättre diagnos- och behandlingsmetoder. Tveka inte att kontakta mig om du har frågor!
      </p>

      <div style={{ width: '100%', margin: '20px auto' }}>
        <Slider {...settings}>
          <div>
            <img src="/images/artikel10.png" alt="lipödem" style={{ width: '100%', height: 'auto', borderRadius: '8px' }} />
          </div>
          <div>
            <img src="/images/artikel11.png" alt="lipödem" style={{ width: '100%', height: 'auto', borderRadius: '8px' }} />
          </div>
          <div>
            <img src="/images/artikel2.png" alt="lipödem" style={{ width: '100%', height: 'auto', borderRadius: '8px' }} />
          </div>
          <div>
            <img src="/images/artikel3.png" alt="lipödem" style={{ width: '100%', height: 'auto', borderRadius: '8px' }} />
          </div>
          <div>
            <img src="/images/artikel7.jpeg" alt="lipödem" style={{ width: '100%', height: 'auto', borderRadius: '8px' }} />
          </div>
          <div>
            <img src="/images/artikel8.jpeg" alt="lipödem" style={{ width: '100%', height: 'auto', borderRadius: '8px' }} />
          </div>
          <div>
            <img src="/images/artikel9.jpeg" alt="lipödem" style={{ width: '100%', height: 'auto', borderRadius: '8px' }} />
          </div>
          <div>
            <img src="/images/artikel6.png" alt="lipödem" style={{ width: '100%', height: 'auto', borderRadius: '8px' }} />
          </div>
          {/* Fler bilder */}
        </Slider>
      </div>
    </div>
  );
}

export default LipodemScreen;
