import React from 'react';
import styled from 'styled-components';

// Styling med styled-components
const Container = styled.div`
  display: flex;
  max-width: 1000px;
  margin: 0 auto;
  padding: 5px;
  gap: 20px;

  // Media query för mobila enheter
  @media (max-width: 768px) {
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
  }
`;

const MainContent = styled.div`
  flex: 3;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    flex: 2;
    width: 100%;
    padding-left: 5px;
  }
`;

const Sidebar = styled.div`
  flex: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f9f9f9;
  padding: 0px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  height: 400px;

  @media (max-width: 768px) {
    flex: 1;
    width: 100%;
    height: 200px

  }
`;

const Image = styled.img`
  width: 100%;
  height: auto;
  margin-bottom: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const Text = styled.p`
  font-size: 16px;
  line-height: 1.6;
  color: #333;

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;


const HeadingImage = styled.img`
  width: 100%;  // Anpassar bildens bredd till containern
  height: auto;
  margin-bottom: 5px;
`;

function Treatments() {
  return (
    <Container>
      <MainContent>
      <HeadingImage src="/images/behandlingsrubrik.png" alt="Behandlingsrubrik" />
        <Text>
        Hos Josefines Wellness erbjuder jag en rad olika behandlingar som är anpassade för att möta dina specifika behov:        </Text>
        <Text>
          <strong>Regndroppsmassage:</strong> En avslappnande behandling där eteriska oljor droppas längs ryggraden och masseras in, vilket ger både kropp och själ en djup känsla av lugn och balans. Behandlingen kan stimulera kroppens egna nervimpulser till hjärnan och ger ökad nervbalansering, stärker immunförsvaret och hjälper dig att slappna av i muskler och leder.
        </Text>
        <Text>
          <strong>Hot Stone Massage:</strong> En lyxig massage där uppvärmda stenar används att massera med på kroppen för att lindra muskelspänningar och främja djup avslappning. Massagen ökar blod- och lymfcirkulationen samt stimulerar cellförnyelsen. Den är djupt avslappnande, minskar stress, är renande och ger ny kraft och energi! Bra behandling vid reumatism, fibromyalgi och annan värk/spänningar.
        </Text>
        <Text>
          <strong>Djupgående Massage:</strong> En kraftfull och effektiv behandling som riktar sig till spända muskler och knutar. Genom djupgående tekniker arbetar vi för att frigöra spänningar och förbättra din rörlighet.
        </Text>
        <Text>
          <strong>Gravidmassage:</strong> En skonsam och anpassad massage för blivande mammor, som lindrar de vanliga besvären under graviditeten såsom ryggvärk och svullnad. Behandlingen ger både fysisk och mental avkoppling.
        </Text>
        <Text>
          <strong>Zonterapi:</strong> Genom att stimulera kroppens zonsystem med tryck och massage aktiveras kroppens naturliga självläkningsprocessen. Zonterapi hjälper till att balansera kroppen och kan lindra en rad olika besvär genom att förbättra blodcirkulationen och frigöra blockeringar. Behandlingen avslutas med en balanserande teknik som ger en känsla av harmoni och välmående.
        </Text>
        <Text>
          <strong>Ir - behandling:</strong> Upplev kraften av helande energi med inframadrass med jade och turmaliner stenar. Designat för att ge en mjukgörande upplevelse. Med högkvalitativa jadestenar och turmaliner stenar, som är inbäddade i madrassen, erbjuder vi dig en verkligt unik värmeterapi i total harmoni.
        </Text>
        <Text>
          <strong>Vaxning:</strong> Hos mig används sockervax, en skonsam metod som är mildare mot huden jämfört med traditionell vaxning. Sockervaxning är idealisk för dig som söker en effektiv och skonsam hårborttagningsmetod. Observera att om du har känslig hud, ytliga blodkärl eller använder blodförtunnande medicin bör du undvika vaxning behandling, eftersom dessa kan påverka din hud negativt.
        </Text>
        <Text>
          <strong>Koppning:</strong> Koppning är en behandling som främjar blodcirkulationen och hjälper kroppen att effektivt transportera bort slaggprodukter från bindväven, vilket stärker immunförsvaret. Det kan beskrivas som en immunförstärkande bindvävsmassage. Jag integrerar även koppning i vissa av mina massagebehandlingar för att lindra spänningar och ömma muskler, vilket ger en djupgående och avslappnande effekt.
        </Text>
        <Text>
          <strong>Medial vägledning:</strong> Jag erbjuder vägledning med hjälp av min mediala förmåga, orakelkort samt pendel.
        </Text>
        <Text>
          För att se en fullständig lista över alla specifika behandlingar,{' '}
          <a
            href="https://www.bokadirekt.se/places/josefines-wellness-9533#book"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: '#007BFF', textDecoration: 'underline', fontWeight: 'bold' }}
          >
            tryck här
          </a>. Bokning kan enkelt göras via SMS eller e-post.
        </Text>
      </MainContent>

      <Sidebar>
        <Image src="/images/utbildningar.png" alt="Bild 1" />
        <Image src="/images/kontakt.png" alt="Bild 2" />
      </Sidebar>
    </Container>
  );
}

export default Treatments;
