import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

// Wrapper för hela toppdelen (bild + navbar)
const Wrapper = styled.div`
  width: 100%; /* Tar hela bredden på små skärmar */
  max-width: 1000px; /* Håller sig till max 1000px på större skärmar */
  margin: 0 auto;
  background-color: #f5e8d7; /* Beige bakgrundsfärg */
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 0px;
`;

// Bildcontainer för bilden ovanför navbaren
const ImageContainer = styled.div`
  width: 100%; /* Anpassar sig till bredden på olika skärmar */
  margin: 0; /* Ingen margin */
  padding: 0; /* Ingen padding */

  img {
    width: 100%; /* Gör att bilden fyller hela bredden av container */
    height: auto;
    margin: 0; /* Ingen margin på bilden */
    padding: 0; /* Ingen padding på bilden */
    display: block; /* Detta kan förhindra extra mellanslag om img är inline */
  }
`;

// Navbar container
const NavbarContainer = styled.div`
  width: 100%; /* Fyll hela bredden */
  background-color: #fff;
  box-shadow: 0 2px 0px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  height: 40px;
  margin: 0px;
  padding: 0px;
`;

const Nav = styled.nav`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const Ul = styled.ul`
  list-style-type: none;
  margin: 0px;
  padding: 10px 20px;
  display: flex;

  @media (max-width: 768px) {
    display: ${(props) => (props.$isOpen ? 'block' : 'none')};  // Använd transient prop $isOpen
    position: absolute;
    top: 88px;
    left: 0;
    background-color: white;
    width: 50%;
    padding: 10px 20px;
    margin-left: 9px;
  }
`;

const Li = styled.li`
  margin-right: 20px;

  @media (max-width: 768px) {
    margin: 10px 0;
  }
`;

const StyledLink = styled(Link)`
  color: black;
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s ease;

  &:hover {
    color: #007bff;
  }
`;

const MenuToggle = styled.div`
  display: none;
  flex-direction: column;
  cursor: pointer;

  @media (max-width: 768px) {
    display: flex;
    margin-left: 10px
  }
`;

const Bar = styled.div`
  height: 4px;
  width: 25px;
  background-color: black;
  margin: 3px 0;
  transition: 0.3s;
`;

//varukorg
const CartLink = styled(Link)`
  color: black;
  text-decoration: none;
  font-weight: bold;
  margin-left: auto;
  padding-right: 10px;
  &:hover {
    color: #007bff;
  }
`;

function Navbar({ cart }) {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const handleLinkClick = () => {
    setIsMobileMenuOpen(false); // Stänger menyn när användaren klickar på en länk
  };

  return (
    <Wrapper>
      {/* Lägg till bilden ovanför navbaren */}
      <ImageContainer>
        <img src="/images/jjrr.png" alt="Logo for the website" />
      </ImageContainer>

      {/* Lägg navbaren precis under bilden */}
      <NavbarContainer>
        <Nav>
          <MenuToggle onClick={toggleMobileMenu}>
            <Bar />
            <Bar />
            <Bar />
          </MenuToggle>
          <Ul $isOpen={isMobileMenuOpen}>
            <Li><StyledLink to="/" onClick={handleLinkClick}>Hem</StyledLink></Li>
            <Li><StyledLink to="/about" onClick={handleLinkClick}>Om Mig</StyledLink></Li>
            <Li><StyledLink to="/compression-clothes" onClick={handleLinkClick}>Kompressionskläder</StyledLink></Li>
            <Li><StyledLink to="/treatments" onClick={handleLinkClick}>Behandlingar</StyledLink></Li>
            <Li><StyledLink to="/lipodem" onClick={handleLinkClick}>Lipödem</StyledLink></Li>
          </Ul>

          <CartLink to="/pay">
            🛒 Varukorg ({cart.length})
          </CartLink>
        </Nav>
      </NavbarContainer>

      {/* Lägg till mellanrum mellan navbar och innehåll */}
      <div style={{ marginTop: '20px' }}>
        {/* Här kommer allt ditt sidinnehåll */}
      </div>
    </Wrapper>
  );
}

export default Navbar;
