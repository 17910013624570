import React, { useEffect, useState } from 'react';
import { homeStyles, mobileStyles } from './HomeStyles';

function HomeScreen() {
  const [styles, setStyles] = useState(homeStyles);

  useEffect(() => {
    const updateStyles = () => {
      if (window.innerWidth < 768) {
        setStyles({ ...homeStyles, ...mobileStyles });
      } else {
        setStyles(homeStyles);
      }
    };
    window.addEventListener('resize', updateStyles);
    updateStyles(); // Initiera stilar
    return () => window.removeEventListener('resize', updateStyles);
  }, []);

  return (
    <div style={styles.container}>
      <img
        src="/images/Header.png"
        alt="Wellness"
        style={styles.image}
      />
      <h1>Välkommen till Josefines Wellness</h1>
      <div style={{ maxWidth: '800px', margin: '0 auto' }}> {/* Justera max-bredden här */}
        <p>
          Hos oss på Josefines Wellness erbjuder vi ett brett utbud av behandlingar inom friskvård och skönhet, utformade för att främja ditt välbefinnande och din livskvalitet.
          <br /><br />
          Vi är också stolta över att kunna erbjuda våra exklusiva kompressionskläder, som förenar stil och funktion på ett unikt sätt.
          <br /><br />
          Kompressionskläder är inte bara designade för att ge ett bekvämt stöd, de förbättrar också blodcirkulationen, minskar svullnad och lindrar muskeltrötthet.
          <br /><br />
          Detta gör dem idealiska för dig som spenderar mycket tid stående eller sittande, samt för idrottare som vill optimera sin prestation och återhämtning.
          <br /><br />
          Utforska vårt utbud av tjänster och produkter för att hitta det som passar just dina behov. Om du har några frågor eller behöver råd, tveka inte att kontakta mig – jag finns här för att hjälpa dig på bästa sätt!
        </p>
      </div>
    </div>
  );
}

export default HomeScreen;
