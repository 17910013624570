import React, { useState } from 'react';
import styled from 'styled-components';

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 700px;
  width: 100%;
  position: relative;
  text-align: center;

  @media (max-width: 768px) {
    max-width: 90%; /* Anpassa bredden på mobila enheter */
    padding: 25px; /* Minska padding på mobiler */
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 32px;
  cursor: pointer;
`;

const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  position: relative; /* Behöver för att placera pilarna absolut */
`;

const Image = styled.img`
  max-width: 400px;
  max-height: 400px;
  object-fit: contain;

  @media (max-width: 768px) {
    max-width: 90%; /* Gör bilden mindre på mobila enheter */
    max-height: 300px;
  }
`;

const ArrowButton = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: #f3e5d5;
  color: black;
  border: none;
  border-radius: 50%;
  padding: 10px;
  font-size: 22px; /* Öka storleken på pilen */
  font-weight: bold; /* Gör pilen fetare */
  cursor: pointer;
  outline: none;
`;

const TextContent = styled.p`
  line-height: 1.5; /* Lägg till line-height för bättre läsbarhet */
  margin: 10px 0;
`;

const ColorDotContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 8px;
  margin-top: 10px;
`;

const ColorDot = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: ${(props) => props.color};
  border: ${(props) => (props.selected ? '2px solid black' : '1px solid #ccc')}; /* Tjockare kant om vald */
  cursor: pointer;
  transition: border 0.3s ease;
`;

const SizeAndColorContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
`;

const GuideLink = styled.span`
  color: purple;
  text-decoration: underline;
  cursor: pointer;
  margin-left: 10px;
`;

const AddToCartButton = styled.button`
  margin-top: 15px;
  padding: 10px 20px;
  background-color: #f3e5d5;
  color: black;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;

  &:hover {
    background-color: #0056b3;
  }

  @media (max-width: 768px) {
    font-size: 14px; /* Justera textstorlek på mobiler */
    padding: 10px 16px;
  }
`;

const PrevButton = styled(ArrowButton)`
  left: 10px;
`;

const NextButton = styled(ArrowButton)`
  right: 10px;
`;

const SizeGuideModal = ({ sizeGuideImage, onClose }) => (
  <ModalOverlay>
    <ModalContent>
      <CloseButton onClick={onClose}>&times;</CloseButton>
      <h4>Storleksguide</h4>
      <img src={sizeGuideImage} alt="Storleksguide" style={{ width: '100%', maxHeight: '400px', objectFit: 'contain' }} />
    </ModalContent>
  </ModalOverlay>
);

const Modal = ({ product, selectedSize, setSelectedSize, selectedColor, setSelectedColor, handleAddToCart, onClose }) => {
  const [currentImage, setCurrentImage] = useState(0);
  const [isSizeGuideOpen, setIsSizeGuideOpen] = useState(false);

  const handleNext = () => {
    setCurrentImage((prev) => (prev + 1) % product.images.length);
  };

  const handlePrev = () => {
    setCurrentImage((prev) => (prev - 1 + product.images.length) % product.images.length);
  };

  const renderSizeOptions = (sizes) => {
    if (!sizes) return null;

    return sizes.split(',').map((size) => (
      <option key={size.trim()} value={size.trim()}>
        {size.trim()}
      </option>
    ));
  };

  const renderColorDots = (colors) => {
    if (!colors) return null;
    return colors.split(',').map((color) => (
      <ColorDot
        key={color.trim()}
        color={color.trim()}
        selected={selectedColor === color.trim()}
        onClick={() => {
          setSelectedColor(color.trim()); // Uppdatera färgvalet till den valda färgen
        }}
      />
    ));
  };

  return (
    <>
      <ModalOverlay>
        <ModalContent>
          <CloseButton onClick={onClose}>&times;</CloseButton>
          <h2>{product.name}</h2>

          <ImageContainer>
            {product.images.length > 1 && (
              <PrevButton onClick={handlePrev}>←</PrevButton>
            )}
            <Image src={product.images[currentImage]} alt={product.name} />
            {product.images.length > 1 && (
              <NextButton onClick={handleNext}>→</NextButton>
            )}
          </ImageContainer>

          <ColorDotContainer>
            {renderColorDots(product.color)} {/* Rendera färgdots här */}
          </ColorDotContainer>

          <TextContent>{product.description}</TextContent>
          <TextContent>Pris: {product.price} kr</TextContent>

          <SizeAndColorContainer>
            <div>
              <label>Välj storlek: </label>
              <select value={selectedSize} onChange={(e) => setSelectedSize(e.target.value)}>
                <option value="">Välj storlek</option>
                {renderSizeOptions(product.size)}
              </select>
            </div>
            <GuideLink onClick={() => setIsSizeGuideOpen(true)}>Storleksguide</GuideLink>
          </SizeAndColorContainer>

          <AddToCartButton onClick={() => handleAddToCart(product)}>
            Lägg till i varukorg
          </AddToCartButton>
        </ModalContent>
      </ModalOverlay>

      {isSizeGuideOpen && (
        <SizeGuideModal sizeGuideImage={product.sizeGuideImage} onClose={() => setIsSizeGuideOpen(false)} />
      )}
    </>
  );
};

export default Modal;
