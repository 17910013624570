import CompressionClothes from '../screens/ProductList.js';
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomeScreen from '../screens/HomeScreen';
import About from '../screens/About';
import Treatments from '../screens/Treatments';
import Lipodem from '../screens/Lipodem';
import Navbar from '../components/Navbar';
import Pay from '../screens/Pay';
import styled from 'styled-components';  // Importera styled-components

// Wrapper som håller både navbar och innehållet centrerat och max-width 1000px
const Wrapper = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  margin-top: 0px;
  background-color: white;
  padding: 0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 1px;

  @media (max-width: 768px) {
    max-width: 100%;  /* Gör wrappern 100% bred på mobiler */
    margin-top: 10px; /* Minska marginalen upptill på mobiler */
    box-shadow: none; /* Ta bort skuggan på mobiler om det behövs */
    border-radius: 0; /* Ingen border-radius på mobil */
  }
`;

// Bakgrundsfärg för hela sidan
const AppBackground = styled.div`
  background-color: #f3e5d5;  // Beige bakgrundsfärg
  min-height: 100vh;

  @media (max-width: 768px) {
    padding: 10px;  /* Lägg till padding på mobiler för att ge lite luft runt innehållet */
  }
`;

function AppRouter({ cart, addToCart, setCart }) {  // Lägg till setCart här
  return (
    <AppBackground>  {/* Applicera beige bakgrundsfärg */}
      <Router>
        <Wrapper> {/* Lägg navbaren i wrappern */}
          <Navbar cart={cart} /> {/* Skicka cart till Navbar */}
        </Wrapper>
        <Wrapper>  {/* Applicera 1000px wrapper runt innehållet */}
          <Routes>
            <Route path="/" element={<HomeScreen />} />
            <Route path="/about" element={<About />} />
            <Route path="/compression-clothes" element={<CompressionClothes addToCart={addToCart} />} /> {/* Skicka addToCart till produkterna */}
            <Route path="/treatments" element={<Treatments />} />
            <Route path="/lipodem" element={<Lipodem />} />
            <Route path="/pay" element={<Pay cart={cart} setCart={setCart} />} /> {/* Skicka cart och setCart till betalsidan */}
          </Routes>
        </Wrapper>
      </Router>
    </AppBackground>
  );
}

export default AppRouter;
